import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/main/api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import {Storage} from "@ionic/storage";
import {BaseCrudComponent} from "../base-crud/base-crud.component";
import {DEBUG} from "@angular/compiler-cli/src/ngtsc/logging/src/console_logger";
//import { isNull } from 'util';

const GET_ALL_EMPLOYEE = environment.Global.API_ALLEMPLOYEE;
const API_ALLEMPLOYEEBYID = environment.Global.API_ALLEMPLOYEEBYID;
const GET_ENTER_OUT_WORK_BY_ID = environment.Global.API_GET_ENTER_OUT_WORK_BY_ID;
const GET_ALL_REQUEST = environment.Global.API_GET_ALL_REQUEST;
const CREATE_ENTER_OUT_WORK = environment.Global.API_CREATE_ENTER_OUT_WORK;
const UPDATE_ENTER_OUT_WORK = environment.Global.API_UPDATE_ENTER_OUT_WORK;
const VALIDATE_QR_EMPLOYEE = environment.Global.API_VALIDATE_QR_EMPLOYEE;
const API_CREATE_ALCOHOLCHECK = environment.Global.API_CREATE_ALCOHOLCHECK;
@Component({
  selector: 'app-qrcode-validation',
  templateUrl: './qrcode-validation.component.html',
  styleUrls: ['./qrcode-validation.component.css']
})
export class QrcodeValidationComponent  extends BaseCrudComponent  implements OnInit, AfterViewInit {
  containData = false;
  form: FormGroup;
  title_button;
  entryRequestId = '';
  qrValid = false;
  isCreating;
  workerId = '';
  worker;
  entryRequest;
  entryRequestValidDate = false;
  endOutWorker;
  translations;
  listTools = [];
  listCars = [];
  newWorkerInDb;
  listaRazones = [];
  estadoDcumentos = "";
  havedata = null;
  detalleDocumentos = [];
  userinfo: any;

  constructor(private elementRef: ElementRef,
    private apiService: ApiService,
    public translate: TranslateService,
    private route: ActivatedRoute,  srv: ApiService,
              modalService: NgbModal,
              storage: Storage,
              router: Router,) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.createForm();
    this.route.queryParams.subscribe(params => {
      this.entryRequestId = params['id'];
      this.workerId = params['worker']
    });
    this.getWorkerById();
    this.getEntryRequest();
    this.endOutWorker = {};
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
    });
     this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument
        .body.style.backgroundColor = '#E4E3E3'; // D0314B rojo skretting
  }

  createForm() {
    this.form = new FormGroup({
      'worker': new FormControl({value: null, disabled: true}, [Validators.required]),
      'typeEmployeeName': new FormControl({value: null, disabled: true}, [Validators.required]),
      'company': new FormControl({value: null, disabled: true}, [Validators.required]),
      'fromDateWork': new FormControl({value: null, disabled: true}, [Validators.required]),
      'toDateWork': new FormControl({value: null, disabled: true}, [Validators.required]),
      'work': new FormControl({value: null, disabled: true}, [Validators.required]),
      'NumberIdentification': new FormControl({value: null, disabled: true}, [Validators.required])
    });
  }



  async getEntryRequest() {
    let validateUrl = VALIDATE_QR_EMPLOYEE + this.workerId + '-'+ this.entryRequestId;
    await this.apiService.getCall(validateUrl).toPromise().then(x => {
      //console.log(x.val)
      if (x.val != null) {
        let empleado: any = x.val;
        this.containData = true;
        this.endOutWorker['CompanyName'] = empleado.namecompany;
        this.endOutWorker['EmployeeId'] = empleado.EmployeeId;
        this.endOutWorker['EmployeeName'] = empleado.Name;
        this.endOutWorker['typeEmployeeName'] = empleado.typeEmployeeName;
        this.endOutWorker['CompanyId'] = empleado.CompanyID;
        this.endOutWorker['StartDate']  = empleado.startDateRequest.split('T')[0];
        this.endOutWorker['EndDte'] = empleado.endDateRequest.split('T')[0];
        this.endOutWorker['ImageProfile'] = empleado.Picture;
        this.endOutWorker['WorkDescription'] = empleado.WorkDescription;
        this.endOutWorker['PlantaId'] = empleado.PlantaId;
        this.endOutWorker['Status_doc'] = empleado.Status_doc == "VALIDADO" ? "Validado" : "Pendiente";
        this.endOutWorker['Status_evaluation'] = empleado.Status_evaluation == "APROBADO" ? "Aprobado" : "Pendiente";
        this.endOutWorker['Status_faltas'] = empleado.Status_faltas;
        this.endOutWorker['statusIngresoFecha'] = empleado.statusIngresoFecha;
        this.endOutWorker['ListsToolsDetails'] = empleado._ListsToolsDetails;
        this.endOutWorker['VehicleByEmployee'] = empleado._VehicleByEmployee;
        this.endOutWorker['NumberIdentification'] = empleado.NumberIdentification;
        if (empleado.statusGeneral == "Ingreso válido") {
          this.qrValid = true;
        } else {
          if (empleado.emergency) {
            if (this.endOutWorker['statusIngresoFecha'] == "DF") {
              this.qrValid = true;
            } else {
              this.listaRazones.push(
                {
                  name: "Estado de documentos",
                  status : this.endOutWorker['Status_doc'],
                  color: this.endOutWorker['Status_doc'] == "Validado" ? 'green' : 'red'
                },
                {
                  name: "Estado de evaluaciones",
                  status : this.endOutWorker['Status_evaluation'],
                  color: this.endOutWorker['Status_evaluation'] != "Pendiente" ? 'green' : 'red'
                },
                {
                  name: "Estado de faltas",
                  status : this.endOutWorker['Status_faltas'] == 'S'? 'Tiene Falta' : 'Sin falta',
                  color: this.endOutWorker['Status_faltas'] == 'S'? 'red' : 'green'
                },
                {
                  name: "Fecha de ingreso",
                  status : this.endOutWorker['statusIngresoFecha'] == "DF" ? 'Fecha Válida' : 'Fecha Inválida',
                  color: this.endOutWorker['statusIngresoFecha'] == "DF" ? 'green' : 'red'
                },
              )
              this.qrValid = false;
            }
          } else {
            this.qrValid = false;
            this.listaRazones.push(
              {
                name: "Estado de documentos",
                status : this.endOutWorker['Status_doc'],
                color: this.endOutWorker['Status_doc'] == "Validado" ? 'green' : 'red'
              },
              {
                name: "Estado de evaluaciones",
                status : this.endOutWorker['Status_evaluation'],
                color: this.endOutWorker['Status_evaluation'] != "Pendiente" ? 'green' : 'red'
              },
              {
                name: "Estado de faltas",
                status : this.endOutWorker['Status_faltas'] == 'S'? 'Tiene Falta' : 'Sin falta',
                color: this.endOutWorker['Status_faltas'] == 'S'? 'red' : 'green'
              },
              {
                name: "Fecha de ingreso",
                status : this.endOutWorker['statusIngresoFecha'] == "DF" ? 'Fecha Válida' : 'Fecha Inválida',
                color: this.endOutWorker['statusIngresoFecha'] == "DF" ? 'green' : 'red'
              },
            )
          }
        }
      } else {
        this.containData = false;
      }
    });
  }

  async getWorkerById() {
    let urlWorker = GET_ENTER_OUT_WORK_BY_ID + this.workerId + '-' + this.entryRequestId;
    await this.apiService.getCall(urlWorker).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          this.newWorkerInDb = x.val;
        } else {
          this.newWorkerInDb = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  confirmAdmission() {
    this.isCreating = true;
    let fechaEnLista = this.formatDate(new Date());
    if (this.newWorkerInDb.length == 0) {
      let obj = {
        EmployeeId: this.workerId,
        CompanyId:  this.endOutWorker['CompanyId'],
        DateEnter: fechaEnLista,
        PlantaID: this.endOutWorker['PlantaId'],
        EntryRequestId : this.entryRequestId
      }
      this.apiService.postCall(CREATE_ENTER_OUT_WORK, obj).toPromise().then( data =>{
        if(data.success){
          this.isCreating = false;
          Swal.fire(this.translations.msg_create, '', 'success');
        } else {
          this.isCreating = false;
          Swal.fire(data.msg, '', 'error');
        }
      },catchError =>{
        this.isCreating = false;
        Swal.fire(catchError.message, '', 'error');
      });
    } else {
      let fechasEntrada = '';
      let fechasSalida = '';
      fechasEntrada = this.newWorkerInDb[0]['DateEnter'] !== null ? this.newWorkerInDb[0]['DateEnter'] : '';
      fechasSalida = this.newWorkerInDb[0]['DateOut'] !== null ? this.newWorkerInDb[0]['DateOut'] : '';

      let listaIEntradas = [];
      let listaFSalidas = [];
      if (fechasEntrada !== ''){
        listaIEntradas = fechasEntrada.split(',');
      }
      if (fechasSalida !== ''){
        listaFSalidas = fechasSalida.split(',');
      }
      let estado = 'Entro';
      if (listaIEntradas.length > listaFSalidas.length) {
        estado = 'Salio';
      } else if (listaIEntradas.length < listaFSalidas.length) {
        estado = 'Entro';
      } else if (listaIEntradas.length === listaFSalidas.length) {
        estado = 'Entro';
      }

      if ( estado === 'Entro') {
        listaIEntradas.push(fechaEnLista);
      } else {
        listaFSalidas.push(fechaEnLista);
      }
      let stringFechasEntradas = listaIEntradas.length > 1 ? listaIEntradas.join(',') : listaIEntradas[0];
      let stringFechasSalidas = listaFSalidas.length > 1 ? listaFSalidas.join(',') : listaFSalidas[0];

      let obj = {
        EmployeeId : this.workerId,
        CompanyId : this.endOutWorker['CompanyId'],
        DateEnter : stringFechasEntradas,
        DateOut : stringFechasSalidas,
        Status  : estado,
      }
      this.apiService.postCall(UPDATE_ENTER_OUT_WORK, obj).toPromise().then( data =>{
        if(data.success){
          this.isCreating = false;
          Swal.fire(this.translations.msg_update, '', 'success');
        } else {
          this.isCreating = false;
          Swal.fire(data.msg, '', 'error');
        }
      },catchError =>{
        this.isCreating = false;
        Swal.fire(catchError.message, '', 'error');
      });
    }
  }

  public openToolsModal (content) {
    this.listTools = this.endOutWorker['ListsToolsDetails'];
    if (this.listTools.length == 0) {
      Swal.fire(this.translations.dont_have_tools, '','info');
    } else {
      this.modalService.open(content, { backdrop: 'static',  keyboard : false, size: 'lg' });
    }
  }

  public openCarsModal (content) {
    this.listCars = this.endOutWorker['VehicleByEmployee'];
    this.havedata = null;
    if (this.listCars.length == 0) {
      Swal.fire(this.translations.dont_have_vehicle, '','info');
    } else {
      this.listCars.forEach( car => {
        let cont = 0;
        let contador = 0;
        if (car._DocumentsVehicle.length > 0) {
          car._DocumentsVehicle.forEach(documento => {
            if (documento.Obligatorio == true) {
              contador ++;
            }
            if (documento.Status == "V" && documento.Obligatorio == true) {
              cont ++;
            }
          })
        } else {
          cont --;
        }
        if (contador == cont) {
          car.estadoGeneral = "Aprobado";
        } else {
          car.estadoGeneral = "Rechazado";
        }
      });
      this.modalService.open(content, { backdrop: 'static',  keyboard : false, size: 'lg' });
    }
  }


  public seeDetailDocument(document) {
    this.detalleDocumentos = document;
    if (this.detalleDocumentos.length > 0) {
      this.havedata = true;
    } else {
      this.havedata = false;
    }
    // console.log(this.detalleDocumentos)
  }

  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString());
  }

  formatDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString())
    + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString()) + ' ' +
    (date.getHours() <= 9 ? `0${date.getHours().toString()}`: date.getHours().toString()) + ':' +
    (date.getMinutes() <= 9 ? `0${date.getMinutes().toString()}`: date.getMinutes().toString()) + ':' +
    (date.getSeconds() <= 9 ? `0${date.getSeconds().toString()}`: date.getSeconds().toString());
  }
  async openUpdateModal( content: any) {
    this.modalService.open( content ,{ backdrop: 'static', keyboard: true, size: 'm', centered: true });

  }
  public closeModal(content: any) {
    this.modalService.dismissAll(content);
  }
  public createAlcoholCheck(modal:any){
    debugger

    if(this.validForm(this.c_obj)){
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
    }

    this.c_obj["EmployeeId"]=this.endOutWorker.EmployeeId;
    this.c_obj["VerificateUser"]=this.userinfo["name"];
    this.srv.postCall(API_CREATE_ALCOHOLCHECK, this.c_obj).subscribe(resp=>{
      if(resp.success){
        Swal.fire(this.translations.AlcoholCheck, '', 'success');
        this.closeModal(modal)
      }
    })
  }
  public validForm(form:any):boolean{
    let mygroup:any = {};
    mygroup = new FormGroup({
      //'Observation': new FormControl(form.Observation, [Validators.required]),
      //'Photo': new FormControl(form.Photo, [Validators.required]),
      'StatusId': new FormControl(form.StatusId, [Validators.required]),

    });
    return mygroup.invalid;
  }
  public getImage({ target }) {
    let image = target.files;
    const reader = new FileReader();
    reader.readAsDataURL(image[0]);
    reader.onload = (_event) => {
      // cambia la foto cuando se va a editar
      if (this.c_obj.Photo !== undefined) {
        this.c_obj.Photo = reader.result;
      }

    }
  }
}
